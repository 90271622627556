import axios, { AxiosProgressEvent, Canceler } from "axios";

import { notificationSuccess } from "@frontend/components/notification";
import config from "@frontend/config";
import settings from "@frontend/config/settings/settings";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { AccessibilityPaymentResponse } from "@core/interfaces/billing";
import {
  BurningTask,
  EnrichedMedia,
  Media,
  MediaComment,
  MediaFile,
  MediaJob,
  SubtitleOrTranscriptionFormat
} from "@core/interfaces/media";
import { UnknownObject } from "@core/interfaces/types";
import { accountStore } from "@core/state/account";
import { authQuery } from "@core/state/auth/auth.query";
import { commentsStore } from "@core/state/comments";
import { dashboardRepository, dashboardStore, FolderId } from "@core/state/dashboard/dashboard.store";
import { downloadQueueStore, QueueFileStatus } from "@core/state/download-queue";
import { editorStateRepository } from "@core/state/editor/editor.state";
import { editorUiStateRepository, SavingFileType, SavingStatus } from "@core/state/editor-ui";
import { UploadFile, uploadStore } from "@core/state/upload";
import {
  getFileFromJob,
  getTranscriptionLanguageProperties,
  isSubtitleFileType,
  transformToEnrichedMedia,
  transformToEnrichedMediaList
} from "@core/utils/media-functions";
import { pluralize } from "@core/utils/strings";
import {
  AspectRatio,
  BurnQuality,
  FileLanguage,
  FileType,
  hashSubtitles,
  Transcription,
  TranscriptionOptions
} from "@getsubly/common";
import { SummaryParams } from "@media-editor/components/editor-sidebar/panels/summary-panel/create-summary-form";
import { AudioFormat } from "@media-editor/contexts/download-dropdown.context";
import { mediaEditorStateRepository } from "@media-editor/state/media-editor.state";
import { TranscriptionMap } from "@media-editor/types";

import { getAccessToken } from "./auth.service";
import { downloadBurntMedia, downloadSubtitles } from "./file.service";
import { handleError } from "./handle-error";
import restApi from "./rest-api";

const baseURL = `${config.apiUrl}/api/v1`;

interface BaseResponse {
  message: string;
}

export const getMediaList = (
  folderId?: FolderId,
  options?: {
    limit?: number;
    skip?: number;
    sort?: string;
    order?: string;
    type?: FileType | null;
    search?: string | null;
  }
): { cancel: Canceler; response: Promise<void> } => {
  let { sort = "created_at" } = options ?? {};
  const { limit = 4, skip = 0, order = "desc", type, search } = options ?? {};

  switch (sort) {
    case "filename":
      sort = "name";
      break;
    case "updated":
      sort = "updated_at";
      break;
    default:
      sort = "created_at";
      break;
  }

  dashboardRepository.updateState({ error: undefined });

  const source = axios.CancelToken.source();

  const response = restApi
    .GET_v2_media_list({
      cancelToken: source.token,
      params: { skip, limit, sort, order, folderId, type, q: search }
    })
    .then(async ({ items, total }) => {
      const enrichedMediaList = transformToEnrichedMediaList(items);

      // Hide all media which seems to be uploading
      const filteredEnrichedMediaList = enrichedMediaList.filter((media) => (media.status as string) !== "UPLOADING");

      if (skip === 0) {
        dashboardRepository.updateState({
          media: filteredEnrichedMediaList,
          totalMediaCount: total
        });
      } else {
        dashboardRepository.updateState({
          media: [...dashboardStore.getValue().media, ...filteredEnrichedMediaList],
          totalMediaCount: total
        });
      }

      filteredEnrichedMediaList.forEach(({ mediaId }) => uploadStore.removeFileByMediaId(mediaId));
    })
    .catch((e) => {
      if (axios.isCancel(e)) return;

      dashboardRepository.updateState({
        error: e.message || "There was an error. Please try again or contact support"
      });
    });

  return {
    cancel: source.cancel,
    response
  };
};

export const getOrFetchMedia = async (mediaId: string): Promise<EnrichedMedia | undefined> => {
  const media = editorStateRepository.media;

  // If no media owner is set, fetch the single Media fresh
  if (media?.owner) {
    return media;
  }

  return await fetchMedia(mediaId);
};

interface GetMediaResponse extends BaseResponse {
  media: Media;
}

export const fetchMedia = async (mediaId: string): Promise<EnrichedMedia | undefined> => {
  try {
    const { data: media } = await axios.get<Media>(`/${getAccountId()}/media/${mediaId}`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
    const enrichedMedia = transformToEnrichedMedia(media);

    editorStateRepository.updateState({ media: enrichedMedia });

    await updateActiveMediaBurnProgressStatus();

    return enrichedMedia;
  } catch (e) {
    handleError(e);
  }
};

export const renameMedia = async (mediaId: string, name: string): Promise<void> => {
  try {
    await axios.put(
      `/${getAccountId()}/media/${mediaId}/rename`,
      { name },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;

    if (isActiveMedia) {
      editorStateRepository.updatePartialMedia({ name });
    } else {
      dashboardRepository.updateMedia(mediaId, { name });
    }
  } catch (e) {
    handleError(e);
  }
};

export const moveMedia = async (mediaId: string, folderId?: string): Promise<void> => {
  const items = [{ mediaId, folderId }];

  try {
    await axios.post(
      `/${getAccountId()}/media/move`,
      { items },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
    const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;

    if (isActiveMedia) {
      editorStateRepository.updatePartialMedia({ folderId });
    } else {
      dashboardRepository.updateMedia(mediaId, { folderId });
    }
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const setMediaFileLanguage = async (
  mediaId: string,
  { languageCode, fileId }: { languageCode: string; fileId?: string }
): Promise<void> => {
  try {
    editorUiStateRepository.updateSavingState({
      savingStatus: SavingStatus.Unsaved,
      savingFileTypes: [SavingFileType.Subtitle]
    });

    const data: { data: { media: Media } } = await axios.put(
      `/media/${mediaId}/set-language`,
      { languageCode, fileId },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
    const enrichedMedia = transformToEnrichedMedia(data.data.media);

    editorStateRepository.updateState({
      media: { ...(editorStateRepository.media ?? {}), ...enrichedMedia }
    });

    if (fileId) {
      editorStateRepository.updateTranscriptionLanguage({
        transcriptionId: fileId,
        languageCode
      });
    } else {
      if (isActiveMedia) {
        editorStateRepository.updatePartialMedia({ languageCode });
      } else {
        dashboardRepository.updateMedia(mediaId, { languageCode });
      }
    }
  } catch (e) {
    handleError(e);
  }

  editorUiStateRepository.updateSavingState({
    savingStatus: SavingStatus.Saved,
    savingFileTypes: [SavingFileType.Subtitle]
  });
};

interface DuplicateResponse extends BaseResponse {
  media: Media;
  newComments: MediaComment[];
}

export const duplicateMedia = async (
  mediaId: string,
  newName: string,
  shareMedia: boolean,
  copyComments: boolean,
  folderId?: string
): Promise<EnrichedMedia | undefined> => {
  const userId = authQuery.userId;

  try {
    const {
      data: { media, newComments }
    } = await axios.post<DuplicateResponse>(
      `/${getAccountId()}/media/${mediaId}/duplicate`,
      { userId, newName, shareMedia, folderId, copyComments },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const enrichedMedia = transformToEnrichedMedia(media);

    if (copyComments) {
      commentsStore.add(newComments);
    }

    return enrichedMedia;
  } catch (e) {
    handleError(e);
  }
};

export interface SnippetClip {
  name: string;
  time: {
    start: number;
    end: number;
  };
  folderId?: string;
}
export const createMediaClips = async (mediaId: string, clips: SnippetClip[]): Promise<void> => {
  try {
    await axios.post(
      `/${getAccountId()}/media/${mediaId}/snippet-clip`,
      { clips },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
  } catch (e) {
    handleError(e);
  }
};

type MakeShareableResponse = GetMediaResponse;
export const makeMediaShareable = async (mediaId: string): Promise<EnrichedMedia | undefined> => {
  try {
    const {
      data: { media }
    } = await axios.post<MakeShareableResponse>(
      `/${getAccountId()}/media/${mediaId}/share`,
      {},
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const enrichedMedia = transformToEnrichedMedia(media);

    editorStateRepository.updateState({ media: enrichedMedia });

    return enrichedMedia;
  } catch (e) {
    handleError(e);
  }
};

export const fetchAllTranscriptions = async (media: EnrichedMedia): Promise<TranscriptionMap> => {
  try {
    const fileIds = [
      media.transcriptions.originalSubtitlesId,
      media.transcriptions.originalTranscriptionId,
      ...media.transcriptions.subtitlesTranslationsIds,
      ...media.transcriptions.transcriptionTranslationsIds
    ].filter((id) => !!id);

    return await fetchTranscriptions(media.mediaId, fileIds);
  } catch (e) {
    handleError(e);
    throw new Error(e);
  }
};

export const fetchTranscriptions = async (mediaId: string, fileIds: string[]): Promise<TranscriptionMap> => {
  const response: TranscriptionMap = {};

  for await (const id of fileIds) {
    response[id] = await fetchTranscriptionById(mediaId, id);
  }
  return response;
};

export const fetchTranscriptionById = async (mediaId: string, fileId: string): Promise<Transcription> => {
  try {
    const { data } = await axios.get<Transcription>(
      `/${getAccountId()}/media/${mediaId}/json/${fileId}?useTransformer=true`,
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    return data;
  } catch (e) {
    handleError(e);
    return [];
  }
};

export interface UploadTranscription {
  transcription: Transcription;
  language: string;
}

interface UploadTranscriptionsResponse {
  message: string;
  description: string;
  media: Media;
}

export const uploadTranscriptions = async (
  accountId: string,
  mediaId: string,
  transcriptions: UploadTranscription[]
): Promise<EnrichedMedia | null> => {
  try {
    const { data } = await axios.post<UploadTranscriptionsResponse>(
      `/${accountId}/media/${mediaId}/upload-subtitles`,
      { transcriptions },
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const enrichedMedia = transformToEnrichedMedia(data.media);

    const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
    if (isActiveMedia) {
      editorStateRepository.updateState({ media: enrichedMedia });
    }

    return enrichedMedia;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const uploadAudioDescription = async (mediaId: string, file: UploadFile, languageCode = "en"): Promise<void> => {
  const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
  const body = {
    filename: file.filename,
    language: languageCode
  };

  editorStateRepository.updatePartialMedia({
    isUploadingAudioDescription: true
  });

  try {
    const {
      data: { uploadUrl, uploadJob }
    } = await axios.post<IUploadAudioDescriptionResponse>(
      `/${getAccountId()}/media/${mediaId}/upload-audio-description`,
      body,
      {
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": await getAccessToken()
        }
      }
    );

    // Save JobId to cancel if required
    const { id: uploadJobId } = uploadJob;

    if (isActiveMedia) {
      editorStateRepository.addMediaJob(uploadJob);
    }

    const type = file.file?.type ?? "audio/mpeg";
    // Upload to bucket
    try {
      await axios.put(uploadUrl, file.file, {
        headers: {
          "Content-Type": type
        },
        onUploadProgress: (e) => uploadAudioDescriptionProgressHandler(mediaId, e)
      });

      if (isActiveMedia) {
        editorStateRepository.updatePartialMedia({
          uploadAudioDescriptionProgress: undefined
        });
      }
    } catch (e) {
      await cancelUploadAudioDescription({ mediaId, jobId: uploadJobId });
    }
  } catch (e) {
    handleError(e);
    if (isActiveMedia) {
      editorStateRepository.updatePartialMedia({
        isUploadingAudioDescription: false
      });
    }
  }
};

const cancelUploadAudioDescription = async (_params: { mediaId: string; jobId: string }): Promise<void> => {
  // TODO
  return Promise.resolve();
};

export interface IGenerateSummaryResponse {
  file: MediaFile;
  summary: string;
}

export const generateSummary = async (
  mediaId: string,
  {
    fileId,
    length,
    display
  }: SummaryParams & {
    fileId: string;
  }
): Promise<void> => {
  const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
  const body = {
    fileId,
    length,
    display
  };

  mediaEditorStateRepository.updateState({
    isGeneratingSummary: true
  });

  try {
    const {
      data: { file, summary }
    } = await axios.post<IGenerateSummaryResponse>(`/${getAccountId()}/media/${mediaId}/generate-summary`, body, {
      baseURL: baseURL,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": await getAccessToken()
      }
    });

    if (isActiveMedia) {
      editorStateRepository.addMediaFile(file);
    }
    mediaEditorStateRepository.updateState({
      summary: {
        content: summary,
        fileId: file.id
      }
    });
  } catch (e) {
    handleError(e);
  } finally {
    if (isActiveMedia) {
      mediaEditorStateRepository.updateState({
        isGeneratingSummary: false
      });
    }
  }
};

export const deleteSummary = async (mediaId: string): Promise<void> => {
  const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
  const summaryFileId = mediaEditorStateRepository?.summaryFileId;

  try {
    await axios.delete<IGenerateSummaryResponse>(`/${getAccountId()}/media/${mediaId}/delete-summary`, {
      baseURL: baseURL,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": await getAccessToken()
      }
    });

    if (isActiveMedia && summaryFileId) {
      editorStateRepository.deleteMediaFile(summaryFileId);
    }
    mediaEditorStateRepository.updateState({
      summary: undefined
    });
  } catch (e) {
    handleError(e);
  }
};

export const burnOrDownloadMedia = async (
  mediaId: string,
  language: FileLanguage,
  options: {
    ratio?: AspectRatio;
    quality?: BurnQuality;
    snippetId?: string;
    useOriginal?: boolean;
  } = {},
  analyticsData: UnknownObject
): Promise<void> => {
  const media = editorStateRepository.media;

  if (!media) {
    return;
  }

  if (options?.snippetId) {
    const snippet = media.assConfig.snippets?.find((s) => s.id === options?.snippetId);
    if (snippet && snippet?.transcriptionId) {
      const transcription = media?.files?.find((f) => f.id === snippet.transcriptionId);
      if (transcription?.language) {
        const snippetLanguage = getTranscriptionLanguageProperties(
          snippet.transcriptionId,
          settings.translation.languages
        );

        language = snippetLanguage ?? language;
      }
    }
  }

  // Get the latest saved version from the server
  const transcription = await fetchTranscriptionById(media.mediaId, language.fileId);

  const burnHash = hashSubtitles(transcription, media.assConfig, options.ratio, language.language, options?.snippetId);

  const { ratio, quality } = options;

  const burntFile = media.files.find((f) => f.burnHash === burnHash);

  const disabledDownload = media.isBurning || !burntFile;
  const canDownload = !disabledDownload;

  if (canDownload && burntFile) {
    downloadBurntMedia({
      mediaId,
      burntFile,
      analyticsData
    });

    return;
  }

  return burnMedia({
    mediaId,
    language,
    transcription,
    ratio,
    quality,
    useOriginal: Boolean(options?.useOriginal),
    snippetId: options?.snippetId
  });
};

interface IBurnMediaParams {
  mediaId: string;
  language: FileLanguage;
  transcription: Transcription;
  ratio?: AspectRatio;
  quality?: BurnQuality;
  useOriginal: boolean;
  snippetId?: string;
}

const burnMedia = async ({
  mediaId,
  language,
  transcription,
  ratio,
  quality,
  useOriginal,
  snippetId
}: IBurnMediaParams) => {
  try {
    const mediaConfig = editorStateRepository.assConfig;

    if (!mediaConfig) {
      throw new Error();
    }

    const hash = hashSubtitles(transcription, mediaConfig, ratio, language.language, snippetId);

    // Don't send a ratio back to the server
    if (ratio === AspectRatio.Original) {
      ratio = undefined;
    }

    const payload = {
      hash,
      ratio,
      quality,
      subtitleFileId: language.fileId,
      useOriginal,
      snippetId
    };
    const url = `/${getAccountId()}/transcriptions/${mediaId}/burn`;

    await axios.post(url, payload, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
    if (isActiveMedia) {
      editorStateRepository.updatePartialMedia({ isBurning: true });
    }
  } catch (e) {
    handleError(e);
  }
};

export const cancelBurnMedia = async (mediaId: string): Promise<void> => {
  const media = editorStateRepository.media;

  if (!media || !media.isBurning) {
    return;
  }

  try {
    await axios.put(
      `/${getAccountId()}/transcriptions/${mediaId}/abort`,
      {},
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    editorStateRepository.updatePartialMedia({ isBurning: false });
  } catch (e) {
    handleError(e);
  }
};

export const cancelMediaJob = async (mediaId: string, jobId: string): Promise<void> => {
  try {
    downloadQueueStore.updateQueueJob(mediaId, jobId, {
      status: QueueFileStatus.Cancelling,
      progress: 0,
      hasDownloaded: true,
      isProcessing: false
    });

    await axios.put(
      `/media/${mediaId}/abort`,
      {
        jobId
      },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    downloadQueueStore.updateQueueJob(mediaId, jobId, {
      status: QueueFileStatus.Cancelled,
      progress: 0,
      hasDownloaded: true,
      isProcessing: false
    });
  } catch (e) {
    handleError(e);
  }
};

export const deleteMedia = async (mediaId: string): Promise<void> => {
  try {
    await axios.delete(`/${getAccountId()}/media/${mediaId}`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const fileSize = editorStateRepository.fileSize ?? 0;

    const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
    if (isActiveMedia) {
      editorStateRepository.updateState({ media: undefined });
    }

    accountStore.decrementStorageBytesUsed(fileSize);
    dashboardRepository.removeMedia(mediaId);
  } catch (e) {
    handleError(e);

    // If it fails removing, fetch media again
    fetchMedia(mediaId);
  }
};

export interface IUploadMediaRequestPayload {
  mediaId?: string;
  name: string;
  language: string;
  folderId?: string;
  filename: string;
  mimeType: string;
  sizeBytes: number;
  transcription?: Transcription;
  settings?: IMediaSettings;
}

export type AutoProcess =
  | {
      type: "transcribe";
    }
  | {
      type: "summary";
    }
  | {
      type: "burn";
      languages: string[];
    };

export interface IMediaSettings {
  skippedTranscription?: boolean;
  customVocabularyWords?: string[];
  summariseAfterTranscribe?: boolean;
  burnAfterTranscribe?: boolean;
  integration?: string;
  drive?: string;
  path?: string;
}

export interface IUploadMediaRequestResponse {
  mediaUrl: string;
  mediaId: string;
}

export interface TranscribeMediaParams {
  mediaId: string;
  languageCode: string;
  customVocabularyWords?: string[];
  options?: TranscriptionOptions;
}

export const transcribeMedia = async ({
  mediaId,
  languageCode,
  customVocabularyWords,
  options
}: TranscribeMediaParams): Promise<void> => {
  try {
    const body = {
      mediaId,
      language: languageCode,
      customVocabularyWords,
      options
    };

    await axios.post(`/${getAccountId()}/media/${mediaId}/transcribe`, body, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
    if (isActiveMedia) {
      editorStateRepository.updatePartialMedia({ isTranscribing: true });
    } else {
      dashboardRepository.updateMedia(mediaId, { isTranscribing: true });
    }

    // Update transcription state
    const media = editorStateRepository.media;
    editorUiStateRepository.updateTranscriptionProgressState(media);
  } catch (e) {
    handleError(e);
  }
};

export interface HumanTranscribeMediaParams {
  mediaId: string;
  languageCode: string;
  quantity: number;
  methodId?: string;
}

export const humanTranscribeMedia = async ({
  mediaId,
  languageCode,
  quantity,
  methodId
}: HumanTranscribeMediaParams): Promise<void> => {
  try {
    const body = {
      mediaId,
      language: languageCode,
      quantity,
      methodId
    };

    await axios.post(`/${getAccountId()}/media/${mediaId}/human-transcribe`, body, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
    if (isActiveMedia) {
      editorStateRepository.updatePartialMedia({ isHumanTranscribing: true });
    } else {
      dashboardRepository.updateMedia(mediaId, { isHumanTranscribing: true });
    }
  } catch (e) {
    handleError(e);
  }
};

export const addTranscribeNotifyUser = async (mediaId: string) => {
  const { data: job } = await axios.put<MediaJob>(
    `/${getAccountId()}/media/${mediaId}/transcribe/notify`,
    {},
    {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
  if (isActiveMedia && job?.id) {
    editorStateRepository.updateMediaJob(job);
  }
};

export const removeTranscribeNotifyUser = async (mediaId: string) => {
  const { data: job } = await axios.put<MediaJob>(
    `/${getAccountId()}/media/${mediaId}/transcribe/cancel-notify`,
    {},
    {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
  if (isActiveMedia && job?.id) {
    editorStateRepository.updateMediaJob(job);
  }
};

export enum LetterCase {
  capitalise = "capitalise",
  uppercase = "uppercase",
  lowercase = "lowercase"
}

export interface GuidelineOptions {
  maxLines?: number;
  maxCharsLine?: number;
  maxCharsSec?: number;
  maxCueDuration?: number;
  maxLineWords?: number;
  newLineAfterPunctuation?: boolean;
  transformLetterCase?: null | LetterCase;
}

export const guidelinesMedia = async ({
  mediaId,
  transcriptionId,
  transcription,
  guidelines,
  height,
  width,
  ratio,
  languageCode
}: {
  mediaId: string;
  transcriptionId: string;
  transcription?: Transcription;
  guidelines: GuidelineOptions;
  height: number;
  width: number;
  ratio: string;
  languageCode?: string;
}): Promise<Transcription | undefined> => {
  try {
    const { data } = await axios.post(
      `/${getAccountId()}/media/${mediaId}/guidelines?useTransformer=true`,
      {
        transcriptionId,
        transcription,
        guidelines,
        height,
        width,
        ratio,
        language: languageCode
      },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
    return data.transcription;
  } catch (e) {
    if (e.response?.status != 200) {
      return e.response.data;
    }
    handleError(e);
    return;
  }
};

export interface IReplaceMediaParams {
  mediaId: string;
  file: UploadFile;
}

export interface IReplaceMediaRequestPayload {
  filename: string;
  sizeBytes: number;
}

export interface IReplaceMediaRequestResponse {
  uploadUrl: string;
  replaceJob: MediaJob;
}

export interface IUploadAudioDescriptionResponse {
  uploadUrl: string;
  uploadJob: MediaJob;
}

const replaceProgressHandler = (mediaId: string, progressEvent: AxiosProgressEvent) => {
  const progress = Math.round((progressEvent.progress ?? 0) * 100);

  const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
  if (isActiveMedia) {
    editorStateRepository.updatePartialMedia({ replaceProgress: progress });
  }
};

const uploadAudioDescriptionProgressHandler = (mediaId: string, progressEvent: AxiosProgressEvent) => {
  const progress = Math.round((progressEvent.progress ?? 0) * 100);

  const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
  if (isActiveMedia) {
    editorStateRepository.updatePartialMedia({
      uploadAudioDescriptionProgress: progress
    });
  }
};

export const replaceMedia = async ({ mediaId, file }: IReplaceMediaParams): Promise<void> => {
  const { filename, mediaInfo } = file;

  const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
  try {
    const type = (file as UploadFile)?.file?.type ?? "video/mp4";
    // Post data
    const body: IReplaceMediaRequestPayload = {
      filename: filename,
      sizeBytes: mediaInfo?.fileSize ?? 0
    };

    editorStateRepository.updatePartialMedia({ isReplacing: true });

    const {
      data: { uploadUrl, replaceJob }
    } = await axios.put<IReplaceMediaRequestResponse>(`/${getAccountId()}/media/${mediaId}/replace`, body, {
      baseURL: baseURL,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": await getAccessToken()
      }
    });

    const { id: replaceJobId } = replaceJob;

    if (isActiveMedia) {
      editorStateRepository.addMediaJob(replaceJob);
    }
    // Upload to bucket
    try {
      await axios.put(uploadUrl, file.file, {
        headers: {
          "Content-Type": type
        },
        onUploadProgress: (e) => replaceProgressHandler(mediaId, e)
      });
      if (isActiveMedia) {
        editorStateRepository.updatePartialMedia({
          replaceProgress: undefined
        });
      }
    } catch (error) {
      await cancelReplaceMedia({ mediaId, jobId: replaceJobId });
    }
  } catch (e) {
    handleError(e);
    if (isActiveMedia) {
      editorStateRepository.updatePartialMedia({ isReplacing: false });
    }
  }
};

export interface ICancelReplaceMediaParams {
  mediaId: string;
  jobId: string;
}

export const cancelReplaceMedia = async ({ mediaId, jobId }: ICancelReplaceMediaParams): Promise<void> => {
  try {
    await axios.put<IReplaceMediaRequestResponse>(
      `/${getAccountId()}/media/${mediaId}/cancel-replace`,
      { jobId },
      {
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": await getAccessToken()
        }
      }
    );

    const isActiveMedia = mediaId && mediaId === editorStateRepository?.mediaId;
    if (isActiveMedia) {
      editorStateRepository.updatePartialMedia({ isReplacing: false });
    }
  } catch (e) {
    handleError(e);
  }
};

export interface UrlInfo {
  url: string;
  filename: string;
  duration: number;
  fileSize: number;
}

export const getVideoPlatformFileInfo = async (url: string): Promise<UrlInfo | undefined> => {
  try {
    const { data } = await axios.post<UrlInfo>(
      `/${getAccountId()}/media/url-info`,
      { url },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    return data;
  } catch (e) {
    handleError(e, true);
    throw e;
  }
};

export const updateActiveMediaBurnProgressStatus = async (): Promise<void> => {
  const media = editorStateRepository.media;

  if (!media?.isBurning) {
    return;
  }

  const {
    data: { progress }
  } = await getBurnProgress(media.mediaId);

  if (progress) {
    editorStateRepository.updatePartialMedia({
      burningTasks: progress.tasks,
      burnProgress: progress.overallProgress
    });
  }
};

interface IBatchBaseFile {
  batchType: BatchType;
  metadata: {
    downloadName: string;
    languageId?: string;
    browserTabId?: number;
  };
  hash?: string;
}

export interface IBatchVideo extends IBatchBaseFile {
  batchType: BatchType.Video;
  hash: string;
  ratio: AspectRatio;
  quality: BurnQuality;
  subtitleFileId: string;
  useOriginal?: boolean;
  snippetId?: string;
  noSubtitles: boolean;
  addDisclaimer?: boolean;
  includeAudioDescription?: boolean;
}

export enum BatchType {
  Video = "Video",
  Audio = "Audio",
  Subtitle = "Subtitle",
  Transcription = "Transcription"
}

export interface IBatchSubtitle extends IBatchBaseFile {
  batchType: BatchType.Subtitle;
  subtitleFileId: string;
  type: SubtitleOrTranscriptionFormat;
  language: FileLanguage;
  hasNoLineBreaks: boolean;
  snippetId?: string;
}

export interface IBatchTranscription extends Omit<IBatchSubtitle, "batchType"> {
  batchType: BatchType.Transcription;
}

export interface IBatchAudio extends IBatchBaseFile {
  batchType: BatchType.Audio;
  extension: AudioFormat;
  quality: BurnQuality;
}

export type IBatchFile = IBatchVideo | IBatchSubtitle | IBatchAudio | IBatchTranscription;

interface BatchDownloadResponse {
  batchId: string;
  jobs: MediaJob[];
}
export const startBatchDownload = async (
  mediaId: string,
  batch: IBatchFile[],
  batchId?: string
): Promise<BatchDownloadResponse> => {
  const { data } = await axios.post<BatchDownloadResponse>(
    `/media/${mediaId}/download`,
    { batch, batchId },
    { baseURL: baseURL, headers: { "x-access-token": await getAccessToken() } }
  );

  return data;
};

export const addDownloadNotifyUser = async (mediaId: string, batchId: string) => {
  await axios.put<void>(
    `/media/${mediaId}/download/${batchId}/notify`,
    {},
    {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );
};

export const downloadByFileId = async (
  fileId: string,
  media: EnrichedMedia,
  analyticsData: UnknownObject
): Promise<void> => {
  const file = media.files.find((f) => f.id === fileId);

  if (!file) {
    return;
  }

  notificationSuccess("We'll start downloading your file soon...");

  if (file.burnt) {
    await downloadBurntMedia({
      mediaId: media.id,
      burntFile: file,
      analyticsData
    });
  } else if (isSubtitleFileType(file.type) && file.languageId) {
    const filename = file?.downloadName ? `${file.downloadName}.${file.extension}` : file.filename;
    await downloadSubtitles(media.id, file.id, filename, analyticsData);
  }
};

export const removeDownloadNotifyUser = async (mediaId: string, batchId: string) => {
  await axios.put<void>(
    `/media/${mediaId}/download/${batchId}/cancel-notify`,
    {},
    { baseURL: baseURL, headers: { "x-access-token": await getAccessToken() } }
  );
};

export const downloadBatchId = async (
  batchId: string,
  media: EnrichedMedia,
  analyticsData: UnknownObject
): Promise<void> => {
  const batchJobs = media.jobs.filter((j) => j.batchId === batchId);

  if (!batchJobs.length) {
    return;
  }

  notificationSuccess(`We'll start downloading your ${pluralize(batchJobs.length, "file")} soon...`);

  const batchFiles = batchJobs.map((j) => {
    return getFileFromJob(media, j.id);
  });

  for await (const file of batchFiles) {
    if (!file) {
      return;
    }

    if (file.burnt) {
      await downloadBurntMedia({
        mediaId: media.id,
        burntFile: file,
        analyticsData
      });
    } else if (isSubtitleFileType(file.type) && file.languageId) {
      const filename = file?.downloadName ? `${file.downloadName}.${file.extension}` : file.filename;
      await downloadSubtitles(media.id, file.id, filename, analyticsData);
    }
  }
};

export const notifyUploadJob = async (mediaId: string): Promise<void> => {
  const userId = authQuery.userId;

  try {
    await axios.put(
      `/media/${mediaId}/upload/notify`,
      { userId },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
  } catch (e) {
    handleError(e);
  }
};

export const removeNotifyUploadJob = async (mediaId: string): Promise<void> => {
  const userId = authQuery.userId;

  try {
    await axios.put(
      `/media/${mediaId}/upload/notify/remove`,
      { userId },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
  } catch (e) {
    handleError(e);
  }
};

// Private functions

interface GetBurnProgressResponse {
  message: string;
  jobId: string;
  processStart: string;
  progress?: {
    overallProgress: number;
    tasks: BurningTask[];
  };
  pending: boolean;
}

const getBurnProgress = async (mediaId: string) => {
  return await axios.get<GetBurnProgressResponse>(`/${getAccountId()}/transcriptions/${mediaId}/status`, {
    baseURL: baseURL,
    headers: { "x-access-token": await getAccessToken() }
  });
};

interface EnhanceTranslationResponse {
  res: Transcription;
}

export const enhanceTranslation = async (
  mediaId: string,
  transcriptionId: string,
  original: FileLanguage,
  translation: FileLanguage
) => {
  const accountId = getAccountId();
  try {
    const { data } = await axios.post<EnhanceTranslationResponse>(
      `/${accountId}/media/${mediaId}/translation/${transcriptionId}/enhance`,
      { original, translation },
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
    return data?.res;
  } catch (e) {
    handleError(e);
    return null;
  }
};

interface MakeMediaAccessibleResponse {
  data: AccessibilityPaymentResponse;
}

export type MakeMediaAccessibleData = {
  mediaIds: string[];
  spokenLanguage: string;
  guidelinesLines: number;
  translationLanguages: string[];
  subtitle: boolean;
  colourBlindness: boolean;
};

export const makeMediaAccessible = async ({
  mediaIds,
  spokenLanguage,
  guidelinesLines
}: MakeMediaAccessibleData): Promise<AccessibilityPaymentResponse> => {
  const accountId = getAccountId();

  const { data } = await axios.post<null, MakeMediaAccessibleResponse>(
    `/${accountId}/media/accessibility`,
    {
      mediaIds,
      spokenLanguage,
      guidelinesLines
    },
    { baseURL, headers: { "x-access-token": await getAccessToken() } }
  );

  if (data?.status === "fail") {
    throw new Error("Error making media accessible");
  }

  return data;
};
