import React from "react";
import { Channel, PresenceChannel } from "pusher-js";
import { Link, useParams } from "react-router-dom";

import { getAccountTeam } from "@frontend/api/account.service";
import { getCredit, getCustomer } from "@frontend/api/billing.service";
import { downloadBurntMediaFromJob } from "@frontend/api/file.service";
import { acceptInvitation } from "@frontend/api/invitation.service";
import { fetchMedia } from "@frontend/api/media.service";
import { getAllNotifications } from "@frontend/api/notifications.service";
import restApi from "@frontend/api/rest-api";
import { EN } from "@frontend/assets/i18n/en";
import config from "@frontend/config";
import { useBrowserTab } from "@frontend/contexts/browser-tab.context";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { BurningTask, Media, MediaComment, MediaFile } from "@core/interfaces/media";
import { UnknownObject } from "@core/interfaces/types";
import { NotificationType, UserNotification } from "@core/interfaces/user";
import { accountQuery, accountStore } from "@core/state/account";
import { authQuery } from "@core/state/auth/auth.query";
import { commentsStore } from "@core/state/comments";
import { dashboardRepository } from "@core/state/dashboard/dashboard.store";
import { downloadQueueQuery, downloadQueueStore, QueueFileStatus } from "@core/state/download-queue";
import { editorStateRepository } from "@core/state/editor/editor.state";
import { uploadStore } from "@core/state/upload";
import { userPresenceStore } from "@core/state/user-presence";
import { isJobFailed, transformToEnrichedMediaListItem } from "@core/utils/media-functions";
import { MediaStatus, RoleName } from "@getsubly/common";
import { useChannel, useClientTrigger, useEvent, usePresenceChannel } from "@harelpls/use-pusher";

import { notificationError, notificationSuccess, notificationWarning } from "../notification";

const usePusherEvent = (
  channel: Channel | PresenceChannel | undefined,
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (data?: any, metadata?: { user_id: string } | undefined) => void
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEvent(channel, eventName, (data: any) => {
    if (!config.isProduction && config.usePusherDebug) {
      const eventExtra = eventName === "MEDIA" && data.action;
      // eslint-disable-next-line no-console
      console.log(
        "[pusher]",
        "channel:",
        channel?.name + ",",
        "eventName:",
        eventName + (eventExtra ? "::" + eventExtra : "") + ",",
        { channel, data }
      );
    }
    callback(data);
  });
};

export enum PusherAction {
  NewMedia = "NEW_MEDIA",
  UpdateMediaStatus = "UPDATE_MEDIA_STATUS",
  UpdateMediaConvertProgress = "UPDATE_MEDIA_CONVERT_PROGRESS",
  UpdateMediaUploadProgress = "UPDATE_MEDIA_UPLOAD_PROGRESS",
  ReplaceSuccess = "REPLACE_SUCCESS",
  ReplaceFail = "REPLACE_FAIL",
  AudioDescriptionUploadSuccess = "AUDIO_DESCRIPTION_UPLOAD_SUCCESS",
  AudioDescriptionUploadFail = "AUDIO_DESCRIPTION_UPLOAD_FAIL",
  MediaWaveform = "MEDIA_WAVEFORM",
  BurnStart = "BURN_START",
  BurnComplete = "BURN_COMPLETE",
  BurnFail = "BURN_FAIL",
  UpdateMediaJobStatus = "UPDATE_MEDIA_JOB_STATUS",
  MediaTranscribeReady = "MEDIA_TRANSCRIBE_READY",
  MediaSummary = "MEDIA_SUMMARY",
  MediaTranslate = "MEDIA_TRANSLATE",
  NewComment = "NEW_COMMENT",
  EditComment = "EDIT_COMMENT",
  DeleteComment = "DELETE_COMMENT",
  DeleteReply = "DELETE_REPLY",
  BillingChange = "BILLING_CHANGE",
  NewInvitation = "NEW_INVITATION",
  TeamCapacityChange = "TEAM_CAPACITY_CHANGE",
  AccountChange = "ACCOUNT_CHANGE",
  NewNotification = "NEW_NOTIFICATION",
  UpdateUserRole = "UPDATE_USER_ROLE"
}

export enum PusherEvent {
  Media = "MEDIA",
  Billing = "BILLING",
  User = "USER",
  Account = "ACCOUNT",
  Comment = "COMMENT",
  Notification = "NOTIFICATION",
  PresenceConnected = "pusher:subscription_succeeded",
  MemberAdded = "pusher:member_added",
  MemberRemoved = "pusher:member_removed",
  RequestSync = "client-request_sync",
  HasOpened = "client-has_opened",
  HasSaved = "client-has_saved",
  HasClosed = "client-has_closed"
}

const IGNORE_PUSHER_ALERTS = [
  PusherAction.NewMedia,
  PusherAction.UpdateMediaStatus,
  PusherAction.UpdateMediaConvertProgress,
  PusherAction.UpdateMediaUploadProgress,
  PusherAction.BurnStart,
  PusherAction.BurnComplete,
  PusherAction.ReplaceFail,
  PusherAction.BurnFail,
  PusherAction.MediaTranslate
];

interface PusherMediaData {
  alert: {
    status: MediaStatus;
    message: string;
    description: string;
  };
  action: PusherAction;
  mediaId: string;
  jobId?: string;
  status: MediaStatus;
  media?: Media;
  progress?: {
    overallProgress: number;
    tasks: BurningTask[];
  };
  languages?: string[];
  waveformFile?: MediaFile;
  percentage?: number;
  needsConverting?: boolean;
  userId?: string;
  hasTranscription?: boolean;
  language?: string;
  isHumanTranscription?: boolean;
}

interface PusherBillingData {
  action: PusherAction;
  freeCredit: number;
  paidCredit: number;
  extraCredit: number;
  paygCredit: number;
}

interface PusherUserData {
  action: PusherAction;
  invitationId: string;
  accountName: string;
}
interface PusherAccountData {
  action: PusherAction;
  planTeamCapacity?: number;
}

interface PusherNotificationData {
  action: PusherAction;
  notification: UserNotification;
}

interface PusherCommentData {
  action: PusherAction;
  mediaId: string;
  comment?: MediaComment;
  commentId?: string;
  replyId?: string;
}

interface PusherProps {
  accountId: string;
  userId: string;
}

const handleMediaPusher = async ({
  data,
  userId,
  analyticsData,
  browserTabId
}: {
  data?: PusherMediaData;
  userId: string;
  analyticsData: UnknownObject;
  browserTabId?: number;
}) => {
  const activeMediaId = editorStateRepository.mediaId;

  if (!data) {
    return;
  }

  const {
    alert,
    action,
    mediaId,
    userId: pusherUserId,
    jobId,
    waveformFile,
    hasTranscription,
    isHumanTranscription,
    progress
  } = data;

  if (pusherUserId && pusherUserId !== userId) {
    return;
  }

  // Media is open on editor
  const isMediaActive = mediaId && activeMediaId === mediaId;

  if (action === PusherAction.UpdateMediaJobStatus) {
    const media = editorStateRepository.media;

    if (!progress || !media || !jobId) {
      return;
    }

    // Update queue job progress
    if (downloadQueueQuery.isCanceledJob(mediaId, jobId)) {
      return;
    }

    downloadQueueStore.updateQueueJob(mediaId, jobId, {
      progress: progress.overallProgress,
      status: QueueFileStatus.Processing
    });
  }

  if (alert && !IGNORE_PUSHER_ALERTS.includes(action)) {
    switch (alert.status) {
      case MediaStatus.Failed:
        notificationError(alert.description);
        break;

      default:
        notificationSuccess(alert.description);
        break;
    }
  }

  switch (action) {
    case PusherAction.UpdateMediaStatus:
      const { status } = data;

      if (status === MediaStatus.Ready) {
        getCredit({ force: true });
        const { media: mediaListItem } = await restApi.GET_v2_media_list_item(data.mediaId);
        dashboardRepository.updateMedia(mediaId, { ...mediaListItem });
      } else if (status === MediaStatus.Failed) {
        notificationError("There was an error with transcribing your file. Please try again or contact support.");
      } else {
        dashboardRepository.updateMedia(mediaId, { status });
      }

      if (isMediaActive) {
        await fetchMedia(mediaId);
      }
      break;
    case PusherAction.UpdateMediaConvertProgress:
      const { percentage } = data;

      if (percentage) {
        if (isMediaActive) {
          editorStateRepository.updateMediaConvertJobProgress(percentage);
        } else {
          if (jobId) {
            dashboardRepository.updateMediaActiveJob({
              mediaId,
              jobId,
              data: { percentage }
            });
          }
        }
      }

      if (editorStateRepository.isReplacing) {
        if (isMediaActive) {
          editorStateRepository.updatePartialMedia({
            replaceProgress: percentage
          });
        }
      }
      break;
    case PusherAction.UpdateMediaUploadProgress:
      const { percentage: progress } = data;

      if (progress) {
        uploadStore.updateFileInQueue(mediaId, { uploadProgress: progress });
      }
      break;
    case PusherAction.NewMedia:
      // handle uploaded media
      const { media } = data;

      if (!media || !media.mediaId) return;

      // Load uploaded media into state

      const { media: mediaListItem } = await restApi.GET_v2_media_list_item(media.mediaId);

      if (mediaListItem) {
        dashboardRepository.prependMedia(transformToEnrichedMediaListItem(mediaListItem));
      }

      // Remove uploaded media from queue
      uploadStore.removeFileByMediaId(media.mediaId);

      break;

    case PusherAction.BurnComplete:
      if (isMediaActive) {
        const updatedMedia = await fetchMedia(mediaId);

        if (!updatedMedia || !jobId) {
          return;
        }

        if (downloadQueueQuery.isCanceledJob(mediaId, jobId) || isJobFailed(updatedMedia, jobId)) {
          return;
        }

        downloadQueueStore.updateQueueJob(mediaId, jobId, {
          progress: 100,
          status: QueueFileStatus.Processing
        });

        downloadBurntMediaFromJob(updatedMedia, jobId, analyticsData, browserTabId);
      }
      break;

    case PusherAction.MediaTranslate:
      if (isMediaActive) {
        await fetchMedia(mediaId);
      } else {
        const { languages } = data;

        dashboardRepository.addMediaTranslations({
          mediaId,
          languages
        });
      }

      break;

    case PusherAction.ReplaceFail:
    case PusherAction.BurnFail:
    case PusherAction.AudioDescriptionUploadFail:
      if (!mediaId) {
        return;
      }

      notificationError(EN.error.defaultMessage);

      if (isMediaActive) {
        await fetchMedia(mediaId);
      }
      break;

    case PusherAction.ReplaceSuccess:
    case PusherAction.AudioDescriptionUploadSuccess:
      if (!mediaId) {
        return;
      }

      if (isMediaActive) {
        await fetchMedia(mediaId);
      }
      break;

    case PusherAction.MediaWaveform:
      if (!mediaId || !waveformFile) {
        return;
      }

      if (isMediaActive) {
        editorStateRepository.addMediaFile(waveformFile);
      }
      break;
    case PusherAction.MediaTranscribeReady:
      editorStateRepository.transcriptionReady$.next({
        mediaId,
        hasTranscription: hasTranscription ?? true,
        isHumanTranscription: isHumanTranscription ?? false
      });

      const isMediaOnDashboard = dashboardRepository.media.some((m) => m.mediaId === mediaId);
      if (isMediaOnDashboard) {
        restApi.GET_v2_media_list_item(mediaId).then(({ media: mediaListItem }) => {
          if (mediaListItem) {
            dashboardRepository.updateMedia(mediaId, mediaListItem);
          }
        });
      }
      break;

    default:
      break;
  }
};

const handleCommentPusher = async ({ data }: { data?: PusherCommentData }) => {
  if (!data) {
    return;
  }

  const { action } = data;

  switch (action) {
    case PusherAction.NewComment:
      if (data.comment) {
        commentsStore.add(data.comment);
      }

      break;

    case PusherAction.EditComment:
      const { comment } = data;
      if (comment) {
        commentsStore.updateComment(comment.id, comment);
      }

      break;

    case PusherAction.DeleteComment:
      const { commentId } = data;
      if (commentId) {
        commentsStore.remove(commentId);
      }

      break;

    case PusherAction.DeleteReply:
      const { replyId } = data;
      if (replyId) {
        commentsStore.remove(replyId);
      }

      break;

    default:
      break;
  }
};

interface PresenceMeData {
  id: string;
  info: {
    username: string;
    name: string;
    photo: string;
    role: RoleName;
  };
}

interface PresenceConnectData {
  count: number;
  me: PresenceMeData;
  members: {
    [id: string]: {
      username: string;
      name: string;
      photo: string;
    };
  };
  myID: string;
}

const handleAccountPresenceConnect = async ({
  userId,
  data,
  clientTrigger
}: {
  userId: string;
  data?: PresenceConnectData;
  clientTrigger: (event: PusherEvent, data: PresenceMeData | PresenceUserUpdateData) => void;
}) => {
  if (!data) {
    return;
  }

  const mediaId = editorStateRepository.mediaId as string;
  const joinedAt = editorStateRepository.loadedAt;
  const role = getCurrentUserRoleForMediaId();

  registerInChannel(userId, mediaId ?? "", role);

  let hasMembers = false;
  if (data.members) {
    for (const [key, value] of Object.entries(data.members)) {
      hasMembers = true;
      userPresenceStore.updateUser(key, value);
    }
  }

  if (hasMembers) {
    clientTrigger(PusherEvent.RequestSync, {
      ...data.me,
      role,
      id: userId
    });
  }
  // This gets processed on load anyway
  if (mediaId) {
    clientTrigger(PusherEvent.HasOpened, {
      userId,
      mediaId,
      joinedAt,
      role
    });
  }
};

interface PresenceMemberChangeData {
  id: string;
  info: {
    username: string;
    name: string;
    photo: string;
  };
}

enum MemberChange {
  Added = "added",
  Removed = "removed"
}
const handleAccountPresenceMemberChange = async ({
  event,
  data
}: {
  event: MemberChange;
  data?: PresenceMemberChangeData;
}) => {
  if (!data) {
    return;
  }

  const { id, info } = data;

  switch (event) {
    case MemberChange.Added:
      userPresenceStore.updateUser(id, info);
      break;
    case MemberChange.Removed:
      userPresenceStore.removeUser(id);
      break;
  }
};

interface PresenceUserUpdateData {
  userId: string;
  mediaId?: string;
  joinedAt?: Date;
  role?: RoleName;
}
enum UserUpdate {
  OpenedMedia = "OpenedMedia",
  SavedMedia = "SavedMedia",
  ClosedMedia = "ClosedMedia"
}
const handleAccountPresenceUserUpdate = async ({
  event,
  data
}: {
  event: UserUpdate;
  data?: PresenceUserUpdateData;
}) => {
  if (!data) {
    return;
  }

  const { userId, mediaId, joinedAt, role } = data;

  switch (event) {
    case UserUpdate.OpenedMedia:
      if (role === RoleName.Viewer) {
        return;
      }

      if (userId && mediaId && joinedAt) {
        userPresenceStore.updateUser(userId, {
          mediaId,
          joinedAt: new Date(joinedAt),
          role
        });
      }
      break;
    case UserUpdate.ClosedMedia:
      if (userId) {
        userPresenceStore.updateUser(userId, {
          mediaId: undefined,
          joinedAt: undefined,
          role: undefined
        });
      }
      break;
  }
};

const registerInChannel = (userId: string, mediaId: string, role: RoleName) => {
  const joinedAt = editorStateRepository.loadedAt;

  if (!joinedAt) {
    return;
  }

  if (role === RoleName.Viewer) {
    return;
  }

  userPresenceStore.updateUser(userId, {
    mediaId,
    joinedAt: new Date(joinedAt),
    role
  });
};

const useAccountPresence = ({
  accountId,
  userId,
  mediaId: propsMediaId
}: {
  accountId: string;
  userId: string;
  mediaId?: string;
}) => {
  const presenceAccountChannel = usePresenceChannel(`presence-${accountId}`);
  const clientTrigger = useClientTrigger(presenceAccountChannel.channel);

  const { mediaId: paramsMediaId = "" } = useParams();
  const mediaId = propsMediaId || paramsMediaId;
  const [lastMediaId, setLastMediaId] = React.useState(mediaId);

  const role = getCurrentUserRoleForMediaId();

  const handleClientTrigger = (eventName: string, data: PresenceMeData | PresenceUserUpdateData) => {
    clientTrigger(eventName, data);
  };

  usePusherEvent(presenceAccountChannel.channel, PusherEvent.PresenceConnected, async (data?: PresenceConnectData) => {
    await handleAccountPresenceConnect({
      data,
      userId,
      clientTrigger: handleClientTrigger
    });
  });

  usePusherEvent(presenceAccountChannel.channel, PusherEvent.MemberAdded, async (data?: PresenceMemberChangeData) => {
    await handleAccountPresenceMemberChange({
      event: MemberChange.Added,
      data
    });
  });

  usePusherEvent(presenceAccountChannel.channel, PusherEvent.MemberRemoved, async (data?: PresenceMemberChangeData) => {
    await handleAccountPresenceMemberChange({
      event: MemberChange.Removed,
      data
    });
  });

  usePusherEvent(presenceAccountChannel.channel, PusherEvent.RequestSync, async (data?: PresenceMeData) => {
    const mediaId = editorStateRepository.mediaId;
    const loadedAt = editorStateRepository.loadedAt;

    if (data?.id && data?.info) {
      userPresenceStore.updateUser(data.id, data.info);
    }

    if (mediaId) {
      clientTrigger(PusherEvent.HasOpened, {
        userId,
        mediaId,
        joinedAt: loadedAt,
        role
      });
    }
  });

  usePusherEvent(presenceAccountChannel.channel, PusherEvent.HasOpened, async (data?: PresenceUserUpdateData) => {
    await handleAccountPresenceUserUpdate({
      event: UserUpdate.OpenedMedia,
      data
    });
  });

  usePusherEvent(presenceAccountChannel.channel, PusherEvent.HasClosed, async (data?: PresenceUserUpdateData) => {
    await handleAccountPresenceUserUpdate({
      event: UserUpdate.ClosedMedia,
      data
    });
  });

  const closeConnection = (userId: string) => {
    handleAccountPresenceUserUpdate({
      event: UserUpdate.ClosedMedia,
      data: { userId }
    });

    handleClientTrigger(PusherEvent.HasClosed, { userId });
  };

  React.useEffect(() => {
    if (mediaId && mediaId !== lastMediaId) {
      setLastMediaId(mediaId);
      handleClientTrigger(PusherEvent.HasOpened, {
        userId,
        mediaId,
        joinedAt: new Date(),
        role
      });
    } else {
      if (!mediaId) {
        setLastMediaId("");
      }
      closeConnection(userId);
    }

    return () => {
      closeConnection(userId);
    };
  }, [mediaId, role]);

  const handleMediaSave = async (data?: { mediaId?: string }) => {
    if (!data?.mediaId) {
      return;
    }

    const isActiveMedia = data.mediaId && editorStateRepository.mediaId === data.mediaId;

    if (isActiveMedia) {
      await fetchMedia(data.mediaId);
    }
  };

  usePusherEvent(presenceAccountChannel.channel, PusherEvent.HasSaved, handleMediaSave);
};

export const Pusher: React.FC<PusherProps> = ({ accountId, userId }) => {
  const accountChannel = useChannel(accountId);
  const userChannel = useChannel(userId);
  const { analyticsData } = useAnalyticsWithAuth();
  const { browserTabId } = useBrowserTab();

  usePusherEvent(accountChannel, PusherEvent.Media, async (data?: PusherMediaData) =>
    handleMediaPusher({ data, userId, analyticsData, browserTabId })
  );

  const handleBillingPusher = async (data?: PusherBillingData) => {
    if (!data) {
      return;
    }

    const { action, freeCredit, paidCredit, extraCredit, paygCredit } = data;

    switch (action) {
      case PusherAction.BillingChange:
        const creditFreeSeconds = freeCredit || 0;
        const creditPaidSeconds = paidCredit || 0;
        const creditExtraSeconds = extraCredit || 0;
        const creditPaygSeconds = paygCredit || 0;
        const totalCredit = creditFreeSeconds + creditPaidSeconds + creditExtraSeconds + creditPaygSeconds;

        accountStore.update({
          credit: {
            loading: true,
            loaded: false,
            free: creditFreeSeconds,
            paid: creditPaidSeconds,
            extra: creditExtraSeconds,
            payg: creditPaygSeconds,
            total: totalCredit
          }
        });

        await getCustomer({ force: true, skipCache: true });
    }
  };

  usePusherEvent(accountChannel, PusherEvent.Billing, handleBillingPusher);

  const handleUserPusher = async (data?: PusherUserData) => {
    if (!data) {
      return;
    }

    if (data.action === PusherAction.NewInvitation) {
      const { invitationId, accountName } = data;

      const handleAccept = async () => {
        await acceptInvitation(invitationId, accountName);
      };

      notificationWarning(
        <>
          You've been invited to join the <strong>{accountName}</strong> workspace.{" "}
          <Link to="#" onClick={handleAccept} className="tw-font-normal tw-text-neutral-900 tw-underline">
            Accept invite
          </Link>
          .
        </>
      );
    } else if (data.action === PusherAction.UpdateUserRole) {
      await getCustomer({ force: true });
    }
  };
  usePusherEvent(userChannel, PusherEvent.User, handleUserPusher);

  const handleNotificationPusher = async (data?: PusherNotificationData) => {
    if (!data) {
      return;
    }

    if (data.action === PusherAction.NewNotification) {
      await getAllNotifications();

      const userAcceptedInvitation = data.notification?.type === NotificationType.TeammateAccepted;
      const userWasInvitedToCurrentAccount = data.notification?.accountId === accountId;

      if (userAcceptedInvitation && userWasInvitedToCurrentAccount) {
        getAccountTeam();
      }
    }
  };
  usePusherEvent(userChannel, PusherEvent.Notification, handleNotificationPusher);

  const handleAccountPusher = async (data?: PusherAccountData) => {
    if (!data) {
      return;
    }

    if (data.action === PusherAction.TeamCapacityChange) {
      const { planTeamCapacity } = data;

      if (planTeamCapacity == null) {
        return;
      }

      accountStore.update({ planTeamCapacity });
    }

    if (data.action === PusherAction.AccountChange) {
      await getCustomer({ force: true });
      await getCredit({ force: true });
    }
  };
  usePusherEvent(accountChannel, PusherEvent.Account, handleAccountPusher);

  usePusherEvent(accountChannel, PusherEvent.Comment, async (data?: PusherCommentData) =>
    handleCommentPusher({ data })
  );

  useAccountPresence({ accountId, userId });

  return null;
};

interface SharedMediaPusherProps {
  mediaId: string;
  accountId: string;
  userId: string;
}

export const SharedMediaPusher: React.FC<SharedMediaPusherProps> = ({ userId, accountId, mediaId }) => {
  const mediaChannel = useChannel(mediaId);

  usePusherEvent(mediaChannel, PusherEvent.Comment, async (data?: PusherCommentData) => handleCommentPusher({ data }));

  useAccountPresence({ accountId, userId, mediaId });

  return null;
};

const getCurrentUserRoleForMediaId = (): RoleName => {
  if (!authQuery.user?.email) {
    return RoleName.Viewer;
  }

  const media = editorStateRepository.media;

  if (authQuery.accountId !== media?.accountId) {
    return RoleName.Viewer;
  }

  return accountQuery.getValue().role ?? RoleName.Viewer;
};
