import React from "react";

import { OAuth } from "@frontend/containers/auth/oauth";
import { AuthLayout } from "@frontend/layouts/auth.layout";

export const OAuthMicrosoftCallbackPage: React.FC<{
  callbackPath: string;
}> = ({ callbackPath }) => {
  return (
    <AuthLayout>
      <OAuth redirectUrl={`${window?.location?.origin}${callbackPath}`} />
    </AuthLayout>
  );
};
