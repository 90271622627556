import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { CancelSubParams, cancelSubscription, pauseSubscription } from "@frontend/api/billing.service";
import { saveSurveyResult } from "@frontend/api/survey.service";
import { EN } from "@frontend/assets/i18n/en";
import { SALES_CONTACT_URL, SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { Textarea } from "@components/form-controls";
import { ChatBubbleLeftEllipsisIcon } from "@components/icons";
import { NewModal } from "@components/new-modal";

import { useAccounts } from "@core/hooks/use-accounts";
import { useDataLayer } from "@core/hooks/use-data-layer";
import { SublyPlan } from "@core/interfaces/billing";
import { SurveyQuestion } from "@core/interfaces/survey";
import { getPlanNameLabel } from "@core/utils/plans";
import { Survey, SurveyType } from "@getsubly/common/dist/interfaces/survey";

import { notificationError, notificationSuccess } from "../notification";

import styles from "./cancel-modal.module.scss";

interface CancelModalProps {
  subscriptionId: string;
  cancelPlan: SublyPlan;
  currentPlan: SublyPlan;
  onDismiss: () => void;
  isTrial?: boolean;
  onPlansPage?: boolean;
}

export const CancelModal: React.FC<CancelModalProps> = (props) => {
  return (
    <NewModal onDismiss={props.onDismiss} showCloseButton size="608" className={styles.modal}>
      <CancelModalContainer {...props} />
    </NewModal>
  );
};

const CancelModalContainer: React.FC<CancelModalProps> = ({ onDismiss, subscriptionId, currentPlan, isTrial }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [hasCompletedSurvey, setHasCompletedSurvey] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [continueToPause, setContinueToPause] = React.useState(false);

  const { subscription } = useAccounts();

  const dataLayer = useDataLayer();

  const handleCancel = async (toPlan: SublyPlan, cancelParams?: CancelSubParams) => {
    try {
      setLoading(true);

      if (toPlan === SublyPlan.Free) {
        await cancelSubscription(subscriptionId, { ...cancelParams, reason });

        if (reason) {
          const surveyResults: Survey = {
            surveyId: SurveyQuestion.CancelReason,
            surveyType: SurveyType.Text,
            result: reason
          };

          await saveSurveyResult(surveyResults);
        }

        const planName = getPlanNameLabel(currentPlan);
        if (isTrial) {
          const event = `Cancelled trial ${planName}`;
          dataLayer(event);
        } else {
          const event = "Downgrade to Free";
          dataLayer(event, { oldPlan: planName });
        }

        notificationSuccess("Your plan will be cancelled");
      }
      navigate(SETTINGS_BILLING_PATH);
      onDismiss();
    } catch (error) {
      setLoading(false);
      notificationError(EN.error.defaultMessage);
      console.error(error);
    }
  };

  const nextInvoiceDatePhrase = subscription?.nextInvoiceDate
    ? " on " + format(new Date(subscription?.nextInvoiceDate), "dd MMMM yyyy")
    : "";

  const handlePause = async (subscriptionId: string) => {
    try {
      setLoading(true);

      await pauseSubscription(subscriptionId);

      notificationSuccess("Your plan has been paused");
    } catch (e) {
      notificationError(EN.error.defaultMessage);
      console.error(e);
    } finally {
      setLoading(false);
      onDismiss();
    }
  };

  if (continueToPause) {
    return (
      <>
        <h5 className={styles.title}>Pause Subly for 30 days</h5>
        <div className="tw-mb-3 tw-text-sm tw-text-neutral-600">Use my one-time pause offer</div>
        <div className={styles.perksImage} />
        <ul className={styles.pausePerksDetails}>
          <li>
            Your pause will start at the end of your billing cycle <b>{nextInvoiceDatePhrase}</b>
          </li>
          <li>You'll receive an email 3 days before your subscription restarts</li>
          <li>You can access your files, but can't work on anything new</li>

          <li>You can unpause at any time</li>
        </ul>

        <div className="tw-mt-6 tw-flex tw-items-center tw-justify-end">
          <Button
            variant="secondary"
            className={classNames("tw-mr-3", styles.buttonV3)}
            onClick={() => setContinueToPause(false)}
            size="36"
          >
            Back
          </Button>
          <Button
            variant="primary"
            className="tw-px-5 tw-py-2"
            loading={loading}
            onClick={() => handlePause(subscriptionId)}
            size="36"
          >
            Pause my subscription
          </Button>
        </div>
      </>
    );
  }

  const currentPlanName = `Subly ${getPlanNameLabel(currentPlan)}`;
  const title = isTrial ? `Cancel Subly trial` : `Cancel ${currentPlanName}`;

  if (!hasCompletedSurvey) {
    const handleBack = () => {
      onDismiss();
    };

    return (
      <>
        <h5 className={styles.title}>{title}</h5>
        <div>
          {subscription?.nextInvoiceDate && (
            <p>
              Your subscription will be cancelled at the end of the billing cycle on{" "}
              <span className="tw-font-semibold">{format(new Date(subscription.nextInvoiceDate), "dd MMMM yyyy")}</span>
              .
            </p>
          )}
          <p className="tw-mb-2 tw-mt-1">To help us improve Subly, we'd love to know why you're cancelling:</p>
        </div>
        <Textarea
          rows={5}
          placeholder="Please explain in as much detail as possible"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          autoFocus
        />

        <div className="tw-mt-6 tw-flex tw-flex-row tw-justify-between">
          <Button
            variant="secondary"
            className={classNames(styles.button, styles.buttonV3)}
            onClick={handleBack}
            size="36"
          >
            Back
          </Button>
          <Button variant="primary" onClick={() => setHasCompletedSurvey(true)} disabled={reason.length < 3} size="36">
            Continue
          </Button>
        </div>
      </>
    );
  }

  if (isTrial) {
    return (
      <>
        <h5 className={styles.title}>{title}</h5>
        <p className="tw-my-2">
          You're about to cancel your Subly Trial. You will lose access to all your features. Are you sure you want to
          cancel?
        </p>

        <div className="tw-mb-2 tw-flex tw-justify-between">
          <Button variant="secondary" className={styles.button} onClick={onDismiss} size="36">
            I want to stay
          </Button>
          <Button
            variant="destructive"
            onClick={() => handleCancel(SublyPlan.Free, { immediate: true })}
            loading={loading}
            size="36"
          >
            Cancel trial
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.cancelBg} />
      <h5 className={classNames(styles.title, "tw-mb-3")}>You're leaving?! For real?</h5>
      <div className="tw-w-[279px]">
        <p className="tw-mb-3 tw-text-sm tw-text-neutral-700">
          We did NOT see that coming. Ok, Ok, let's see if we can work this out.
        </p>
        <p className="tw-mb-6 tw-text-sm tw-text-neutral-700">
          Would you like to <b>contact us</b> and see if we can work something out instead?
        </p>
      </div>
      <Button
        variant="primary"
        className={classNames(styles.button, "tw-mb-10 tw-w-[147px]")}
        type="link"
        isBlank
        to={SALES_CONTACT_URL}
      >
        <ChatBubbleLeftEllipsisIcon className="tw-mr-2 tw-h-5 tw-w-5" /> Contact us
      </Button>
      <div className="tw-z-10 tw-flex tw-justify-between">
        <Button variant="secondary" className={classNames(styles.button, styles.buttonV3)} onClick={onDismiss}>
          I want to stay
        </Button>
        <Button
          variant="destructive"
          onClick={() => handleCancel(SublyPlan.Free)}
          loading={loading}
          className={styles.button}
        >
          Cancel now
        </Button>
      </div>
    </>
  );
};
