import React from "react";
import { Helmet } from "react-helmet-async";
import { useForm, UseFormReturn } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";

import { Alert } from "@frontend/components/alert/alert";
import { Divider } from "@frontend/components/divider/divider";
import { GoogleButton } from "@frontend/components/social-buttons/google-button/google-button";
import { useAnalytics } from "@frontend/contexts/analytics.context";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { AuthLayout } from "@frontend/layouts/auth.layout";
import { FORGOT_PASSWORD_PATH, PRIVACY_URL, SIGN_UP_PATH, TERMS_URL } from "@frontend/routes";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";
import { KeyFillIcon, MailLineIcon } from "@components/icons";
import { Logo } from "@components/logo";
import { TextButton } from "@components/text-button";

import { useQuery } from "@core/hooks/use-query";
import { isEmbeddedWebView } from "@core/utils/browser";
import { updateUrlParams } from "@core/utils/links";
import { emailPattern } from "@core/utils/regex-patterns";
import { ErrorMessage } from "@hookform/error-message";

import { EmbeddedWebviewMsg } from "../containers/auth/embedded-webview-msg";
import { EmailSentView } from "../containers/auth/views/email-sent-view";

export const LoginPage: React.FC = () => {
  const { trackEvent } = useAnalytics();
  const { queryParams } = useQuery<{
    alertMessage?: string;
    withPassword?: "true" | "false";
    invite?: string;
    redirect?: string;
    mode?: "sso";
  }>();
  const { state } = useLocation();

  const withPassword = queryParams.withPassword === "true";
  const invite = queryParams.invite;
  const redirect = queryParams.redirect;
  const mode = queryParams.mode;

  React.useEffect(() => {
    trackEvent("User in Sign in");
  }, []);

  const [loading, setLoading] = React.useState(false);
  const [ssoMode, setSsoMode] = React.useState(mode === "sso");
  const [submittedEmail, setSubmittedEmail] = React.useState("");
  const [restoreUser, setRestoreUser] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(queryParams.alertMessage ?? state?.alertMessage);
  const formState = useForm<{ email: string; password?: string }>();

  const { loginWithPassword, requestMagicLink } = useAuthProvider();

  const primaryButtonName = React.useMemo(() => {
    if (restoreUser) return "Restore user";
    if (withPassword) return "Log in";
    if (ssoMode) return "Continue with SSO";
    if (Boolean(queryParams.invite)) return "Log in with email";
    return "Continue with email";
  }, [restoreUser, withPassword, ssoMode]);

  const switchMode = () => {
    const newValue = !ssoMode;
    setSsoMode(newValue);
    updateUrlParams("mode", newValue ? "sso" : undefined);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const params = formState.getValues();

    const { success, alertMessage, status } = withPassword
      ? await loginWithPassword({ email: params.email, password: params.password!, invite })
      : await requestMagicLink({ email: params.email, invite, restore: restoreUser, redirect, sso: ssoMode });

    if (success) {
      setSubmittedEmail(params.email);
      trackEvent("Complete Sign in request");
    } else {
      switch (alertMessage) {
        case "User has been deleted.":
          formState.setError(
            "email",
            {
              type: "custom",
              message: "User has been deleted. Do you want to restore the user?"
            },
            { shouldFocus: true }
          );
          setRestoreUser(true);
          break;
        default:
          if ([401, 409, 422].includes(status)) {
            formState.setError("email", { type: "custom", message: alertMessage }, { shouldFocus: true });
          } else {
            setAlertMessage(alertMessage);
          }
          break;
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Subly - Log in</title>
      </Helmet>
      {submittedEmail ? (
        <EmailSentView email={submittedEmail} onClickResend={handleSubmit} />
      ) : (
        <LoginForm
          formState={formState}
          ssoMode={ssoMode}
          withPassword={withPassword}
          primaryButtonName={primaryButtonName}
          isAcceptingInvite={Boolean(queryParams.invite)}
          loading={loading}
          alertMessage={alertMessage}
          onSwitchMode={switchMode}
          onLogin={handleSubmit}
          onSetAlertMessage={setAlertMessage}
        />
      )}
    </>
  );
};

interface LoginFormProps {
  formState: UseFormReturn<{ email: string; password?: string }>;
  ssoMode: boolean;
  withPassword?: boolean;
  primaryButtonName: string;
  loading: boolean;
  alertMessage?: string;
  isAcceptingInvite?: boolean;
  onSwitchMode: () => void;
  onLogin: () => void;
  onSetAlertMessage: (message: string) => void;
}
export const LoginForm: React.FC<LoginFormProps> = (props) => {
  const signUpHref = React.useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("mode");
    return `${SIGN_UP_PATH}?${searchParams.toString()}`;
  }, []);

  const isWebView = isEmbeddedWebView();

  return (
    <AuthLayout>
      {props.alertMessage && (
        <Alert
          danger
          className="tw-w-full tw-max-w-sm"
          title="Something went wrong!"
          closable
          onClose={() => props.onSetAlertMessage("")}
        >
          {props.alertMessage}
        </Alert>
      )}
      <Logo color="black" size="lg" className="tw-mx-auto tw-mb-4 tw-p-2" />
      <div className="tw-mb-6">
        <h2 className="tw-mb-1 tw-text-center tw-text-h2 tw-font-semibold tw-text-neutral-900">
          {props.isAcceptingInvite ? (
            <>
              You're Invited to Join a<br />
              Workspace
            </>
          ) : (
            "Welcome back!"
          )}
        </h2>
        <p className="tw-text-center tw-text-md tw-font-normal tw-text-neutral-700">
          {props.isAcceptingInvite ? (
            <>
              Sign in or create an account to accept your invitation
              <br />
              and start collaborating.
            </>
          ) : (
            "Get back to making you media content accessible."
          )}
        </p>
      </div>
      <div className="tw-w-full tw-max-w-sm">
        {isWebView ? (
          <EmbeddedWebviewMsg />
        ) : (
          <>
            <div className="tw-flex tw-flex-col tw-gap-4">
              <GoogleButton action="sign-in" />
              <Button
                variant="secondary"
                className="tw-flex tw-w-full"
                icon={props.ssoMode ? <MailLineIcon /> : <KeyFillIcon />}
                onClick={props.onSwitchMode}
              >
                {props.ssoMode ? "Sign in with Email" : "Single sign-on (SSO)"}
              </Button>
            </div>
            <Divider>or</Divider>
          </>
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.onLogin();
          }}
        >
          <div className="tw-mb-4">
            <Input
              id="email"
              placeholder="Email Address"
              hasError={Boolean(props.formState.formState.errors.email)}
              autoCapitalize="off"
              type="email"
              {...props.formState.register("email", { required: true, pattern: emailPattern })}
            />
            <ErrorMessage
              errors={props.formState.formState.errors}
              name="email"
              render={({ message }) => {
                return <p className="tw-mt-2 tw-text-sm tw-text-destructive-600">{message}</p>;
              }}
            />
          </div>
          {props.withPassword && (
            <div className="tw-mb-4">
              <Input
                id="password"
                placeholder="Password"
                hasError={Boolean(props.formState.formState.errors.password)}
                type="password"
                {...props.formState.register("password", { required: true })}
              />
            </div>
          )}

          <Button
            className="tw-w-full tw-bg-primary-500 !tw-text-sm tw-text-neutral-50 hover:tw-bg-primary-600"
            type="submit"
            loading={props.loading}
          >
            {props.primaryButtonName}
          </Button>
        </form>

        {props.withPassword && (
          <div className="tw-mt-3 tw-text-center">
            <Link className="tw-text-sm tw-text-primary-500" to={FORGOT_PASSWORD_PATH}>
              Forgot your password?
            </Link>
          </div>
        )}

        <div className="tw-mx-auto tw-mt-3 tw-max-w-[257px] tw-text-center tw-text-xs tw-font-medium tw-text-neutral-500">
          By proceeding, you agree to the{" "}
          <a href={TERMS_URL} target="_blank" rel="noopener noreferrer" className="tw-italic tw-text-primary-600">
            Terms of Use
          </a>{" "}
          and{" "}
          <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer" className="tw-italic tw-text-primary-600">
            Privacy Policy
          </a>
        </div>

        <div className="tw-mt-16 tw-text-center tw-text-sm tw-text-neutral-700">
          Don't have an account?{" "}
          <TextButton type="link" to={signUpHref}>
            Sign up
          </TextButton>
        </div>
      </div>
    </AuthLayout>
  );
};
